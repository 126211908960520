@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__header {
  background-color: transparent !important;
  border-bottom: none !important;
  padding-top: 8px;
  position: relative;
  border-radius: 0px !important;
}

.dark .react-datepicker__current-month {
  color: white;
}

.dark .react-datepicker__day-name {
  color: white;
}

.dark .react-datepicker__month-container {
  background-color: #1f2937;
}

.dark .react-datepicker__day {
  color: white;
}

.react-datepicker__current-month {
  padding: 0px 8px 12px;
}

.react-datepicker__navigation-icon::before {
  border-color: #979797 !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__day--selected {
  background: rgb(22, 22, 26) !important;
  color: rgb(255, 255, 255) !important;
}

.dark .react-datepicker__day:hover {
  background-color: #f0f0f0;
  color: black;
}

.dark .react-datepicker__day--selected {
  background: rgb(255, 255, 255) !important;
  color: rgb(22, 22, 26) !important;
}
